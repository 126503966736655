export const content = {
    texts: {
        title: 'Gender inequality in politics',
        subtitle: 'A visual exploration of women representation in national parliaments between 1990 and 2018. ',
    },
    urls: {
        project2: 'https%3A%2F%2Ffabiandinklage.com%2Fprojects%2Fgender-equality',
        project: 'https://fabiandinklage.com/projects/gender-equality/',
        socialtag: 'https://fabiandinklage.com/projects/gender-equality/social-tag.jpg',
        data: 'http://data.un.org/_Docs/SYB/CSV/SYB61_T05_Seats%20held%20by%20Women%20in%20Parliament.csv',
        dataportal: 'http://data.un.org/',
    },
    contact: {
        mail_url: 'mailto:mail@fabiandinklage.com',
        mail_text: 'mail(at)fabiandinklage.com',
        legalnote_url: 'https://fabiandinklage.com/legalnote.html',
        legalnote_text: 'legal note',
    }
}