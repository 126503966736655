<template>
</template>

<script>
    import { mapState } from 'vuex';
    import * as topojson from 'topojson-client';

    import {
        select as d3Select,
        geoOrthographic  as d3gGeoOrthographic,
        geoCentroid  as d3GeoCentroid,
        geoPath as d3GeoPath,
        json as d3Json,
        tsv as d3Tsv,
        transition as d3Transition,
        interpolate as d3Interpolate
    } from 'd3';

    export default {
        name: 'Globe',
        computed: {
            ...mapState(['highlighted_id', 'countries']),
        },
        
        props: ['world', 'countries', 'world'],
        data() {
			return {
				projection: null,
                path: null,
                svg: null,
                wrapper: null,
                focused: false,
                focusedCountry: false,
                width: 100,
                height: 100,
                countryById: {},
                countriesFeatures: null,
                world: null,
			}
		},
        watch: {
            highlighted_id: function() {
                this.rotate(this.highlighted_id)
            }
        },
        methods: {
            rotate(id) {
                const rotate = this.projection.rotate();
                this.focusedCountry = this.countriesFeatures.find(country => (country.id == id));
                const p = d3GeoCentroid(this.focusedCountry);

                this.svg.selectAll(".focused").classed("focused", this.focused = false);
                this.svg.selectAll(".focused").classed("focused", this.focused = false);

                if (this.focusedCountry) {
                    this.transition(p);
                }
            },
            transition(p) {
                    d3Transition()
                    .duration(250)
                    .tween("rotate", () => {
                        var r = d3Interpolate(this.projection.rotate(), [-p[0], -p[1]]);
                        return (t) => {
                            this.projection.rotate(r(t));
                            this.svg.selectAll("path").attr("d", this.path)
                                .classed("focused", (d, i) => { 
                                    if (this.focusedCountry) {
                                        return d.id == this.focusedCountry.id 
                                        ?
                                        this.focused = d
                                        :
                                        false;
                                    }
                                });
                        };
                    })
            },
            init() {
                this.projection = d3gGeoOrthographic()
                    .scale(40)
                    .rotate([0, 0])
                    .translate([this.width / 2, this.height / 2])
                    .clipAngle(90);

                this.path = d3GeoPath()
                    .projection(this.projection);

                this.wrapper = d3Select("#app").append("div")
                    .attr('class', 'globe-wrapper')

                this.svg = this.wrapper.append("svg")
                    .attr("width", this.width)
                    .attr("height", this.height);

                this.svg.append("path")
                    .datum({ type: "Sphere" })
                    .attr("class", "water")
                    .attr("d", this.path)

                this.countriesFeatures = topojson.feature(this.world, this.world.objects.countries).features;

                this.world = this.svg.selectAll("path.land")
                    .data(this.countriesFeatures)
                    .enter().append("path")
                    .attr("class", "land")
                    .attr("d", this.path)
                    .attr("id", d => (d.id))

            },
        },
        created() {
            this.init();
        }
    }
</script>

<style lang="scss">
    @import '../assets/style/main.scss';

    .globe-wrapper {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 100;
        height: 100;
        z-index: 1000;
        background: none;

        @media screen and (max-width: 1024px) {
            display: none;
        }

        .water {
            fill: white;
        }

        .land {
            fill: #c7c6d7;
            stroke: #c7c6d7;
            stroke-width: 0.5px;
        }

        .focused {
            fill: #424158;
            stroke: white;
        }
    }

    select {
        position: absolute;
        top: 20px;
        left: 580px;
        border: solid #ccc 1px;
        padding: 3px;
        box-shadow: inset 1px 1px 2px #ddd8dc;
    }
</style>


