import Vue from 'vue'
import store from './store'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import AppRadial from './AppRadial.vue';

import Meta from 'vue-meta'
Vue.use(Meta);


Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(AppRadial),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
