<template>
    <div class="vis-wrapper">
        <Legend :first="legend.first" :last="legend.last" :country="legend.country" :continent="legend.continent" :color="legend.color"/>
        <div id="radial" class="radial"></div>
    </div>
</template>

<script>
    import Legend from '../components/Legend.vue';

    import { mapState } from 'vuex';

    import { 
        select as d3Select,
        selectAll as d3SelectAll,
        scaleLinear as d3ScaleLinear,
        scaleBand as d3ScaleBand,
        axisLeft as d3AxisLeft,
        arc as d3Arc
    } from 'd3';

    export default {
        name: 'Radial',
        props: ['data', 'allAxis'],
        components: {
            Legend
        },
        data() {
            return {
                legend: {
                    first: null,
                    last: null,
                    country: null,
                    continent: null
                },
                svg: null,
                segments: null,
                selectedLast: null,
                wrapper: null,
                overlaySegments: null,
                margin: {
                    left: 100,
                    top: 150,
                    bottom: 100,
                    right: 100
                },
                radians: -2 * Math.PI,
                axis: null,
                factor: 50,
                total: null,
                connections: null,
                connectionsWrapper: null,
                x: null,
                coords: null,
                overlays: null,
                circleRadius: 3,
                circles: { first: [], last: []},
                circlesWrapper: null,
                y: null,
                values: null,
                axisTexts: null,
                clientWidth: null,
                clientHeight: null,
                axisWrapper: null,
                radius: null,
                circleYPos: null,
                circleXPos: null,
                size: null,
                segmentRadius: null,
                calcRange: null,
                offset: 50,
                maxValue: 75,
                yearNew: 2018,
                yearOld: 1990,
                colors: {
                    'Europe': '#fc9f88', 
                    'Oceania': '#EB758F', 
                    'Asia': '#C7498A', 
                    'North America': '#3A1353', 
                    'South America': '#581C7F', 
                    'Africa': '#872E93'
                    }
            }
        },
        computed: {
            ...mapState(['ranking']),
        },
        watch: {
            data: function() {
                this.updateCircles('first');
                this.updateCircles('last');
                this.updateConnections();
                this.updateAxis();
                // this.updateValues('first');
                // this.updateValues('last');
            }
        },
        methods: {
            init() {
                this.x = d3ScaleBand()
                    .domain(this.data.map(d => d.Country))
                    .range([0, 2 * Math.PI])
                    .align(0)

                this.clientWidth = d3Select('body').node().getBoundingClientRect().width;
                this.clientHeight = d3Select('body').node().getBoundingClientRect().height;

                this.wrapper = d3Select('#radial');

                d3Select('.vis-wrapper').attr('style', `height: ${this.clientHeight}px`)

                this.size = this.clientHeight;

                this.width = this.size;
                this.height = this.size;

                // d3Select('svg.svg')
                //     .attr('width', `${this.clientHeight}px`)
                //     .attr('height', `${this.clientHeight}px`)

                this.total = this.data.length;
                this.radius = (this.width / 2);

                this.y = d3ScaleLinear()
                    .domain([0,this.maxValue])
                    .range([this.radius - this.margin.bottom, this.offset + 50]) // pixels height of diagram

                const values = [0,25,50,75]

                this.axisLeft = d3AxisLeft(this.y)
                    .tickValues(values)
                    .tickFormat(function(d) {
                        return this.parentNode.nextSibling ? "\xa0" + d : d + " %";
                    })

                this.circleXPos = (i) => { return Math.sin(i*this.radians / this.total + Math.PI); } 
                this.circleYPos = (i) => { return Math.cos(i*this.radians / this.total + Math.PI); }

                this.calcRange = (d) => {
                    const length = ((this.radius - this.margin.top - this.offset) * (d / this.maxValue) + this.offset);
                    return length;
                }

                this.segmentRadius = (d) => {
                    return ((this.radius - this.margin.top - this.offset) * (d / this.maxValue) + this.offset)
                }

                this.svg = this.wrapper.append('svg')
                    .classed('svg', true)
                    .attr('width', this.width)
                    .attr('height', this.height)

                this.segments = this.svg.append('g')
                    .classed('segments-wrapper', true)

                this.connectionsWrapper = this.svg.append('g')
                    .classed('connections-wrapper', true)

                this.circlesWrapper = this.svg.append('g')
                    .classed('circles-wrapper', true)

                this.axisWrapper = this.svg.append('g')
                    .classed('axis-wrapper', true)

                this.axisWrapper
                    .call(this.axisLeft)
                    .attr('transform', `translate(${this.radius + 12}, ${this.offset})`)

                this.axisWrapper.select('.domain')
                    .remove()

                this.axisWrapper.selectAll('g.tick > line').remove();
            },
            createSegments() {
                const axisTicks = [(this.segmentRadius(0)), (this.segmentRadius(25)), (this.segmentRadius(50)), (this.segmentRadius(75))]

                for (let index = 0; index < axisTicks.length; index++) {
                    let offset = axisTicks[index];
                    let startAngle = 3;
                    let endAngle = 357;

                    if (index == 0) {
                        startAngle = 10;
                        endAngle = 350;
                    } else if (index == 1) {
                        startAngle = 5;
                        endAngle = 353;
                    } else if (index == 2) {
                        startAngle = 2;
                        endAngle = 356;
                    } else if (index == 3) {
                        startAngle = 1.5;
                        endAngle = 356;
                    }

                    var arc = d3Arc()
                        .innerRadius(offset)
                        .outerRadius(offset)
                        .startAngle(startAngle * (Math.PI/180)) //converting from degs to radians
                        .endAngle(endAngle * (Math.PI/180)) //just radians

                    this.segments.append("path")
                        .attr("d", arc)
                        .attr("transform", `translate(${this.radius}, ${this.radius})`)
                        .attr("class", "axis")
                        .style('stroke', '#E0E0E0')
                        .style("stroke-opacity", "1")
                        .style("fill", "none")
                        .style("stroke-width", "1px")
                }
            },
            createAxis() {
                this.axis = this.segments.selectAll('g.axis')
                    .data(this.data)

                this.axis.exit().remove();

                this.axis = this.axis
                    .enter()
                    .append('g')
                    .classed('axis', true)
                    .attr("transform", (d,i) => {
                        const pi = 3.14159;

                        const length = this.radius - this.margin.top + 20
                        const xPos = length * (Math.sin(i*this.radians/this.data.length + pi)) + this.radius;
                        const yPos = length * (Math.cos(i*this.radians/this.data.length + pi)) + this.radius;

                        let angle = (this.x(d.Country) + this.x.bandwidth() / 2) * 180 / pi - 90;

                        angle = Number(angle.toFixed(1));
                        
                        return `
                        translate(${xPos},${yPos})
                        rotate(${angle})
                        `
                    })
                    .merge(this.axis)
                
                this.axisTexts = this.axis.append('text')
                    .text(d => { return d.Country })
                    .attr('data-ranking', (d) => { 
                        if (this.ranking.top.includes(d.id)) {
                            return 'top10' 
                        } else if (this.ranking.bottom.includes(d.id)) {
                            return 'bottom10' 
                        }
                    })
                    .attr("text-anchor", d => (((this.x(d.Country) + this.x.bandwidth() / 2) + Math.PI / 2) + Math.PI / 2) % (2 * Math.PI) > Math.PI
                        ? "start"
                        : "end"
                    )
                    .attr('class', (d,i) => { return `legend-${i} legend` })
                    .attr('data-continent', (d) => { return d.continent })
                    .attr('data-country', (d) => { return d.Country })
                    .attr('data-id', (d) => { return d.id })
                    .attr("transform", d => (((this.x(d.Country) + this.x.bandwidth() / 2) + Math.PI / 2) + Math.PI / 2) % (2 * Math.PI) > Math.PI
                        ? "rotate(0)"
                        : "rotate(180)"
                    )
                    .attr('style', (d) => { return `fill: ${this.colors[d.continent]}` })
            },
            createOverlaySegments() {
                const total = this.data.length;

                this.overlaySegments = this.svg.append('g')
                    .classed('OverlaySegments', true)

                this.overlays = this.overlaySegments.selectAll('path.axis')
                    .data(this.data)

                this.overlays.exit().remove() 

                this.overlays.enter()
                    .append('svg:path')
                    .attr('d', (d, i) => {
                        let startAngle = (360 / total) * (i);
                        let endAngle = (360 / total) * (i + 1);

                        var arc = d3Arc()
                            .innerRadius(20)
                            .outerRadius(this.radius)
                            .startAngle(startAngle * (Math.PI/180)) //converting from degs to radians
                            .endAngle(endAngle * (Math.PI/180)) //just radians
                        
                        return arc();
                    })
                    .attr("transform", `translate(${this.radius}, ${this.radius})`)
                    .attr("class", "axis")
                    .style('stroke', '#E0E0E0')
                    .style("stroke-opacity", "1")
                    .style("fill", "white")
                    .style("opacity", "0.0")
                    .style("stroke-width", "1px")
                    .on('mouseover', (d,i) => { return this.handleMouseOver(this.data[i].id)})
                    .on('mouseout', (d,i) => { return this.handleMouseOut(this.data[i].id)})
                    .merge(this.overlays);

            },
            updateAxis() {
                this.axisTexts
                    .data(this.data)

                const that = this;
                    
                d3SelectAll("text.legend").each(function(d) {
                    d3Select(this)
                        .text(`${d.Country}`)
                        .style('fill', that.colors[d.continent])
                        .attr('data-continent', d.continent)
                        .attr('data-country', d.Country)
                        .attr('data-id', (d) => { return d.id})
                        .attr('data-ranking', function() { 
                        if (that.ranking.top.includes(d.id)) {
                            return 'top10' 
                        } else if (that.ranking.bottom.includes(d.id)) {
                            return 'bottom10' 
                        }
                    })
                });
            },
            updateConnections() {
                this.connections = this.connectionsWrapper.selectAll('.line')
                    .data(this.data)

                this.connections.exit().remove() 

                this.connections = this.connections
                    .enter()
                    .append('svg:line')
                    .attr('x1', 0)
                    .attr('y1', 0)
                    .attr('x2', 0)
                    .attr('y2', 0)
                    .attr("class", "line")
                    .attr('stroke', (d) => { return this.colors[d.continent] }) // color here later
                    .style("stroke-opacity", ".5")
                    .style("stroke-width", "1px")
                    .attr('data-country', (d) => { return d.Country })
                    .attr('data-id', (d) => { return d.id })
                    .attr('transform', `translate(${this.radius},${this.radius})`)
                    .attr('data-continent', (d) => { return d.continent })
                    .attr('data-ranking', (d) => { 
                        if (this.ranking.top.includes(d.id)) {
                            return 'top10' 
                        } else if (this.ranking.bottom.includes(d.id)) {
                            return 'bottom10' 
                        }
                    })
                    .attr('data-id', (d) => { return d.id })
                    .merge(this.connections);

                this.connections
                    .transition()
                    .duration(750)
                    .attr('data-continent', (d) => { return d.continent })
                    .attr('data-id', (d) => { return d.id })
                    .attr('data-ranking', (d) => { 
                        if (this.ranking.top.includes(d.id)) {
                            return 'top10' 
                        } else if (this.ranking.bottom.includes(d.id)) {
                            return 'bottom10' 
                        }
                    })
                    .attr('stroke', (d) => { return this.colors[d.continent] }) // color here later
                    .attr('x1', (d,i) => {
                        const cat = this.yearOld;
                        if (d != undefined) {
                            let polar_coord_x = this.calcRange(d[cat]) * this.circleXPos(i);
                            return polar_coord_x;
                        }
                    })
                    .attr('y1', (d,i) => {
                        const cat = this.yearOld;

                        if (d != undefined) {
                            let polar_coord_x = this.calcRange(d[cat]) * this.circleYPos(i);
                            return polar_coord_x;
                        }
                    })
                    .attr('x2', (d,i) => {
                        const cat = this.yearNew;
                        if (d != undefined) {
                            let polar_coord_x = this.calcRange(d[cat]) * this.circleXPos(i);
                            return polar_coord_x;
                        }
                    })
                    .attr('y2', (d,i) => {
                        const cat = this.yearNew;

                        if (d != undefined) {
                            let polar_coord_x = this.calcRange(d[cat]) * this.circleYPos(i);
                            return polar_coord_x;
                        }
                    })

            },
            updateValues (category) {
                this.values = this.circlesWrapper.selectAll(`.${category}-value`)
                    .data(this.data)
                
                this.values.exit().remove()

                this.values = this.values
                    .enter()
                    .append('text')
                    .classed(`${category}-value`, true)
                    .classed(`value`, true)
                    .attr('data-continent', (d) => { return d.continent })
                    .attr('data-country', (d) => { return d.Country })
                    .attr('data-ranking', (d) => { 
                        if (this.ranking.top.includes(d.id)) {
                            return 'top10' 
                        } else if (this.ranking.bottom.includes(d.id)) {
                            return 'bottom10' 
                        }
                    })
                    .attr('id', (d,i) => { return `value-${i}` })
                    .attr('transform', (d,i) => {
                        let cat = category == 'first' ? this.yearOld : this.yearNew;
                        let polar_coord_x; 
                        let polar_coord_y;

                        if (d != undefined) {
                            polar_coord_x = this.calcRange(d[cat]) * this.circleXPos(i);
                            polar_coord_y = this.calcRange(d[cat]) * this.circleYPos(i);
                        }

                        return `translate(${this.radius + polar_coord_x + 12},${this.radius + polar_coord_y + 6})`;
                    })
                    .attr('style', `fill: black`)
                    .text((d) => { 
                        let cat = category == 'first' ? this.yearOld : this.yearNew;
                        return d[cat]
                     })
                     .merge(this.values);
            },
            updateCircles(category) {
                this.circles[category] = this.circlesWrapper.selectAll(`.${category}-circle`)
                    .data(this.data)

                this.circles[category].exit().remove()

                this.circles[category] = this.circles[category]
                    .enter()
                    .append('svg:circle')
                    .attr('data-ranking', (d) => { 
                        if (this.ranking.top.includes(d.id)) {
                            return 'top10' 
                        } else if (this.ranking.bottom.includes(d.id)) {
                            return 'bottom10' 
                        }
                    })
                    .classed(`${category}-circle`, true)
                    .attr('id', (d) => { return `circle-${d.id}` })
                    .attr('fill', (d) => { return category == 'last' ? this.colors[d.continent] : '#FFFFFF'; })
                    .attr('stroke', (d) => { return category == 'last' ? '#FFFFFF' : this.colors[d.continent]; })
                    .attr('r', () => { return category == 'last' ? this.circleRadius + 1 : this.circleRadius})
                    .attr('data-continent', (d) => { return d.continent })
                    .attr('data-country', (d) => { return d.Country })
                    .attr('data-first', (d) => { return d[1990] })
                    .attr('data-last', (d) => { return d[2018] })
                    .attr('data-id', (d) => { return d.id })
                    .attr('data-idGlobe', (d) => { return d.idGlobe })
                    .attr('data-ranking', (d) => { 
                        if (this.ranking.top.includes(d.id)) {
                            return 'top10' 
                        } else if (this.ranking.bottom.includes(d.id)) {
                            return 'bottom10' 
                        }
                    })
                    .attr('cx', this.width / 2)
                    .attr('cy', this.height / 2)
                    .merge(this.circles[category]);

                this.circles[category]
                    .transition()
                    .duration(750)
                    .attr('fill', (d) => { return category == 'last' ? this.colors[d.continent] : '#FFFFFF'; })
                    .attr('stroke', (d) => { return category == 'last' ? '#FFFFFF' : this.colors[d.continent]; })
                    .attr('data-continent', (d) => { return d.continent })
                    .attr('id', (d) => { return `circle-${d.id}` })
                    .attr('data-country', (d) => { return d.Country })
                    .attr('data-id', (d) => { return d.id })
                    .attr('data-ranking', (d) => { 
                        if (this.ranking.top.includes(d.id)) {
                            return 'top10' 
                        } else if (this.ranking.bottom.includes(d.id)) {
                            return 'bottom10' 
                        }
                    })
                    .attr('cx', (d,i) => {
                        const cat = category == 'first' ? this.yearOld : this.yearNew;

                        if (d != undefined) {
                            let polar_coord_x = this.calcRange(d[cat]) * this.circleXPos(i);
                            return polar_coord_x;
                        }
                    })
                    .attr('cy', (d,i) => {
                        const cat = category == 'first' ? this.yearOld : this.yearNew;

                        if (d != undefined) {
                            let polar_coord_x = this.calcRange(d[cat]) * this.circleYPos(i);
                            return polar_coord_x;
                        }
                    })
                    .attr('transform', `translate(${this.radius},${this.radius})`)
            },
            toggleOpacity(boolean) {
                d3SelectAll('circle.last-circle')
                    .classed('inactive', boolean)

                d3SelectAll('circle.first-circle')
                    .classed('inactive', boolean)

                d3SelectAll('text.legend')
                    .classed('inactive', boolean)

                d3SelectAll('line.line')
                    .classed('inactive', boolean)
            },
            highlightHovered(i, boolean) {
                this.$store.dispatch('SET_HIGHLIGHTED_ID', i);

                d3SelectAll(`circle[data-id="${i}"]`)
                    .classed('active', boolean)

                d3SelectAll(`#value[data-id="${i}"].last-value`)
                    .classed('active', boolean)

                d3SelectAll(`text[data-id="${i}"]`)
                    .classed('active', boolean)

                d3SelectAll(`line[data-id="${i}"]`)
                    .classed('active', boolean)
            },
            togglePercentage(id) {
                let matchLast = null;

                if (this.selectedLast != null) {
                    matchLast = this.data.filter(c => { return c.id == this.selectedLast });

                    d3SelectAll(`text[data-id="${this.selectedLast}"]`)
                        .classed('active', false)
                        .text(`${matchLast[0].Country}`)
                }
                
                const match = this.data.filter(c => { return c.id == id });
                
                d3SelectAll(`text[data-id="${id}"]`)
                    .classed('active', true)
                    .text(`${match[0].Country} (${match[0][2018]}%)`)

                this.selectedLast = id;

            },
            removePercentage() {
                    const matchLast = this.data.filter(c => { return c.id == this.selectedLast });

                    d3SelectAll(`text[data-id="${this.selectedLast}"]`)
                        .classed('active', false)
                        .text(`${matchLast[0].Country}`)
            },
            handleMouseOver(i) {
                this.updateLegend(i);
                this.toggleOpacity(true);
                this.highlightHovered(i,true);
                this.togglePercentage(i);
            },
            handleMouseOut(i) {
                this.updateLegend(null);
                this.toggleOpacity(false);
                this.highlightHovered(i,false);
                this.removePercentage(i)
            },
            updateLegend(d) {
                
                const match = this.data.filter(c => { return c.id == d })

                d = match[0];

                if (d == null) {
                    this.legend.continent = null;
                    this.legend.country = null;
                    this.legend.first = null;
                    this.legend.last = null;
                    this.legend.color = null;
                } else {
                    this.legend.country = d['Country'];
                    this.legend.continent = d['continent'];
                    this.legend.first = d[1990];
                    this.legend.last = d[2018];
                    this.legend.color = this.colors[d.continent];
                }
            }
        },
        mounted() {
            this.init();
            this.createSegments();
            // this.updateValues('first');
            // this.updateValues('last');
            this.updateCircles('last');
            this.updateCircles('first');
            this.updateConnections();
            this.createAxis();
            this.createOverlaySegments();
        }
    }
</script>

<style lang="scss">
    @import '../assets/style/main';

    .vis-wrapper {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;

        @media #{$mobile} {
            display: none;
        }

        div.radial {
            margin: 0 auto;
        }

        text, line , circle {
            transition: opacity $time-s linear;
            cursor: pointer;
        }

        .legend {
            font-size: 10px;
            cursor: pointer;
        }

        .inactive {
            opacity: 0.15;
            transition: opacity $time-s linear;
        }

        .value {
            opacity: 0;
        }

        .active {
            opacity: 1;
        }

        text.legend {
            // font-weight: bold;
            letter-spacing: .25px;

            &.inactive {
                font-weight: normal;
            }
        }

        text {
            font-family: 'Karla';
            color: $color-black;
        }
    }

</style>

