<template>
	<div class="app" id="app">
		<Globe v-if="dataReady" :world="world" :countries="countries"/>
		<Description/>
		<Radial v-if="dataReady" :data="data" :allAxis="allAxis"/>
	</div>
</template>

<script>
	import Radial from './components/Radial.vue';
	import Description from './components/Description.vue';
	import Globe from './components/Globe.vue';

	import { mapState } from 'vuex';
	import {
		csv as d3Csv,
		json as d3Json,
		tsv as d3Tsv,
	} from 'd3';

	import { content } from './assets/content.js';

	import Vue from 'vue'
	import vSelect from 'vue-select'

	Vue.component('v-select', vSelect);



	export default {
		name: 'app',
		components: {
			Radial,
			Description,
			Globe
		},
		data() {
			return {
				allAxis: [],
			}
		},
		metaInfo() {
			return {
				title: content.texts.title,
				meta: [
					{ charset: 'utf-8' },
					{ name: 'viewport', content: 'width=device-width, initial-scale=1'},
					{ hid: 'description', name: 'description', content: content.texts.subtitle},
					{ property: 'og:url', content: content.urls.project},
					{ property: 'og:type', content: 'website'},
					{ property: 'og:title', content: content.texts.title},
					{ property: 'og:description', content: content.texts.title},
					{ property: 'og:image', content: content.urls.socialtag},
					{ name: 'twitter:card', content: "summary_large_image"},
					{ name: 'twitter:title', content: content.texts.title},
					{ name: 'twitter:description', content: content.texts.subtitle},
					{ name: 'twitter:image', content: content.urls.socialtag},
					{ name: 'twitter:image:alt', content: content.texts.title},
				]
			}	
		},
		watch: {
			sortBy: function() {
				if (this.sortBy == 'development') {
					const data = this.sortByIncrease(this.data);
					this.$store.dispatch("SET_DATA", data);
				} else if (this.sortBy == 'continents') {
					const data = this.sortByContinent(this.data);
					this.$store.dispatch("SET_DATA", data);
				} else if (this.sortBy == 'first') {
					const data = this.sortByFirst(this.data);
					this.$store.dispatch("SET_DATA", data);
				} else if (this.sortBy == 'last') {
					const data = this.sortByLast(this.data);
					this.$store.dispatch("SET_DATA", data);
				} else if (this.sortBy == 'alphabet') {
					const data = this.sortByAlphabet(this.data);
					this.$store.dispatch("SET_DATA", data);
				}
			},
		},
		computed: {
			...mapState(['data_url','data', 'year', 'world', 'countries', 'hovered', 'sortBy']),
			dataReady() {
				if (this.data == null || this.world == null || this.countries == null) {
					return false
				} else if (this.data.length == this.allAxis.length) {
					return true
				} else {
					return false;
				}
			},
			content() {
				return content;
			}
		},
		methods: {
			fetchData() {
				let data = null;
				let countries = null;
				let world = null;



				d3Json('https://raw.githubusercontent.com/d3/d3.github.com/master/world-110m.v1.json')
					.then(r => {
						d3Tsv('./data/world-110m-country-names.tsv')
							.then(c => {
								d3Csv(this.data_url)
									.then((d) => {
										data = this.prepareData(d, c);
										data = this.sortByLast(data)
										data = this.sortByContinent(data)
										data = this.sortByAlphabet(data)
										this.$store.dispatch("SET_DATA", data);
										this.$store.dispatch("SET_WORLD", r);
										this.$store.dispatch("SET_COUNTRIES", c);
									});
							})
					})
			},
			prepareData(data, countries) {
				data = data.map((d) => { 
					if (d[2018] != "no data" && d[2010] != "no data" && d[1990] != "no data") {
						return {
							diff: this.diff(parseFloat(d[1990]), parseFloat(d[2018])),
							Country: d.Country,
							"2018": parseFloat(d[2018]),
							"1990": parseFloat(d[1990]),
							"2000": parseFloat(d[2000]),
							"2010": parseFloat(d[2010]),
							"political_regime": this.parsePoliticalRegime(parseFloat(d.political_regime)),
							"continent": d.continent,
							"id": d['T05'],
						}
					}
				})

				return data;
			},
			sortByFirst(data) {
				this.allAxis = [];
				data = data.sort((a,b) => { return b[1990] - a[1990]  })
				data = data.filter((item) => { return item != undefined  })
				this.arrAllAxis(data);

				return data;
			},
			sortByLast(data) {
				this.allAxis = [];
				data = data.sort((a,b) => { return b[2018] - a[2018]  })
				data = data.filter((item) => { return item != undefined  })
				this.arrAllAxis(data);

				return data;
			},
			sortByIncrease(data) {
				this.allAxis = [];

				data = data.sort((a,b) => { return b.diff - a.diff  })
				data = data.filter((item) => { return item != undefined  })

				this.arrAllAxis(data);

				return data;
			},
			sortByAlphabet(data) {
				this.allAxis = [];

				data = data.filter((item) => { return item != undefined  })
				data = data.sort();

				this.arrAllAxis(data);

				return data;
			},
			sortByContinent(data) {
				this.allAxis = [];

				data = data.sort(function(a, b) {
					if(a.continent < b.continent) { return -1; }
					if(a.continent > b.continent) { return 1; }
					return 0;
				})

				data = data.filter((item) => { return item != undefined  })

				this.arrAllAxis(data);

				return data;
			},
			diff(val01, val02) {
				const result = val01 < val02 ? val02 - val01 : val01 - val02;
				return parseFloat(result);
			},
			arrAllAxis(data) {
				data.forEach(item => {
                    this.allAxis.push(item.Country);
                });
			},
			parsePoliticalRegime(val) {
				if (val < -5) {
					return 'Autocracy';
				} else if (val > -6 && val < 1) {
					return 'Closed Anocracy';
				} else if (val > 0 && val < 6) {
					return 'Open Anocracy';
				} else if (val > 5) {
					return 'Democracy';
				}
			}
		},
		created() {
			this.fetchData();
		}
	}
</script>

<style lang="scss">
	@import './assets/style/main.scss';

	html, body {
		margin: 0;
		font-family: 'Karla';
		height: 100%;
		width: 100%;
	}

	.app {
		display: flex;
		flex-direction: row;
		align-items: stretch;
	}

	#app {
		font-family: 'Karla';
		color: $color-black;
	}
</style>
