<template>
    <div class="wrapper-description">

        <h1 class="title">{{ this.content.texts.title }}</h1>
        <h2 class="intro">{{ this.content.texts.subtitle }}</h2>

        <br>

        <div class="wrapper-mobile-info">
            <p>
                This visualisation is developed for larger screens. For an interactive version revisit on your computer.
            </p>
        </div>

        <br class="only-mobile">

        <img class="img-mobile" src="../assets/images/right_mobile.jpg" alt="">

        <br>

        <h2 class="subtitle not-mobile" style="margin-top: 0;">Sort by</h2>

        <div class="mt-m not-mobile btn-wrapper">
            <span id="button-continents" class="button is-continents  mr-m mb-m active" @click="setSelected('continents')">Continents</span>
            <span id="button-first" class="button is-first  mr-m mb-m" @click="setSelected('first')">% 1990</span>
            <span id="button-last" class="button is-last mr-m mb-m" @click="setSelected('last')">% 2018</span>
            <!-- <span id="button-alphabet" class="button is-alphabet  mr-m mb-m" @click="setSelected('alphabet')">Alphabet</span> -->
            <span id="button-development" class="button is-development  mr-m mb-m" @click="setSelected('development')">Progress</span>
        </div>

        <br>

        <h2 class="subtitle not-mobile">Filter</h2>

        <div class="mt-m not-mobile btn-wrapper">
            <span id="button-top-ten" class="button is-top-ten nobr mr-m mb-m" @click="hover('top10')">Top 10</span>
            <span id="button-bottom-ten" class="button is-bottom-ten nobr mr-m mb-m" @click="hover('bottom10')">Bottom 10</span>
            <span id="button-all" class="button is-black nobr mr-m mb-m highlight" @click="hover('All')">All countries</span>
            <span id="button-africa" class="button is-africa mr-m mb-m" @click="hover('Africa')">Africa</span>
            <span id="button-asia" class="button is-asia nobr mr-m mb-m" @click="hover('Asia')">Asia</span>
            <span id="button-europe" class="button is-europe nobr mr-m mb-m" @click="hover('Europe')">Europe</span>
            <span id="button-north-america" class="button is-north-america nobr mr-m mb-m" @click="hover('North America')">North America</span>
            <span id="button-oceania" class="button is-oceania nobr mr-m mb-m" @click="hover('Oceania')">Oceania</span>
            <span id="button-south-america" class="button is-south-america nobr mr-m mb-m" @click="hover('South America')">South America</span>
        </div>

        <br>

        <h2 class="subtitle mb-s">Sources</h2>

        <div class="sources">
            <p>The visualisation is based on the <span class="i"> ”Seats held by women in national parliament“ </span> <a class="l" :href="content.urls.data">dataset</a> from the <a class="l" :href="content.urls.dataportal">UN data portal</a>.</p>
            <p>Countries with incomplete data were neglected in the data set.</p>
        </div>

        <br>


        <div class="meta">
            <h2 class="subtitle mb-s ">Share this</h2>
            <Share class=""/>

            <!-- <br> -->

            <h2 class="subtitle mb-s ">Contact</h2>
            <span class="small"><a class="l" :href="content.contact.mail_url">{{content.contact.mail_text}}</a></span>
            <br>
            <span class="small"><a class="l" :href="content.contact.legalnote_url">{{content.contact.legalnote_text}}</a></span>

        </div>



    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { 
        selectAll as d3SelectAll,
        select as d3Select,
    } from 'd3';

    import { content } from '../assets/content';

    import Share from './Share';

    export default {
        name: 'Description',
        components: {
            Share
        },
        computed: {
            ...mapState(['hovered', 'selected', 'sortBy', 'highlighted']),
        },
        data() {
            return {
                content: content
            }
        },
        mounted() {
            // const height = d3Select('body').node().getBoundingClientRect().height;
            // d3Select('.wrapper-description').attr('style', `height: ${height}px`);
        },
        methods: {
            setSelected(val) {
                this.hover('All');
                this.$store.dispatch("SET_SORTBY", val);
                this.toggleButton(val, 'select');
                this.toggleOpacity(false);
                this.hoverFromSelect()
            },
            setHighlighted(val) {
                this.$store.dispatch("SET_HIGHLIGHTED", val);
            },
            hover(hover) {
                this.$store.dispatch("SET_HOVERED", hover);
                this.toggleButton(hover, 'highlight');
                this.toggleOpacity(false);
                this.setHighlighted(hover);
            },
            hoverFromSelect() {
                d3Select(`span#button-all`)
                    .classed('highlight', true)
            },
            toggleOpacity(boolean) {
                d3SelectAll('circle.last-circle')
                    .classed('inactive', boolean)

                d3SelectAll('circle.first-circle')
                    .classed('inactive', boolean)

                d3SelectAll('text.legend')
                    .classed('inactive', boolean)

                d3SelectAll('line.line')
                    .classed('inactive', boolean)
            },
            toggleButton(country, type) {
                const dict = {
                    'Europe': 'europe',
                    'North America': 'north-america',
                    'South America': 'south-america',
                    'Oceania': 'oceania',
                    'Africa': 'africa',
                    'Asia': 'asia',
                    'All': 'all',
                    'continents': 'continents',
                    'development': 'development',
                    'first': 'first',
                    'alphabet': 'alphabet',
                    'last': 'last',
                    'top10': 'top-ten',
                    'bottom10': 'bottom-ten',
                }

                const keys = Object.keys(dict);

                const className = type == 'highlight' ? 'highlight' : 'active'; 

                keys.forEach(key => {
                    if (country != key) {
                        d3Select(`span#button-${dict[key]}`)
                            .classed(className, false); 
                    }
                })

                d3Select(`span#button-${dict[country]}`)
                    .classed(className, function() { 
                        return !this.classList.contains(className); 
                    });
            },
            highlightHovered(category, boolean, value) {
                d3SelectAll(`circle.last-circle[data-${category}="${value}"]`)
                    .classed('inactive', boolean)

                d3SelectAll(`circle.first-circle[data-${category}="${value}"]`)
                    .classed('inactive', boolean)

                d3SelectAll(`text.legend[data-${category}="${value}"]`)
                    .classed('inactive', boolean)

                d3SelectAll(`line.line[data-${category}="${value}"]`)
                    .classed('inactive', boolean)
            },
        },
        watch: {
            hovered: function() {
                if (this.hovered == 'Europe' && this.selected == 'all') {
                    this.toggleOpacity(true);
                    this.highlightHovered('continent', false, 'Europe');
                } else if (this.hovered == 'North America' && this.selected == 'all') {
                    this.toggleOpacity(true);
                    this.highlightHovered('continent', false, 'North America');
                } else if (this.hovered == 'South America' && this.selected == 'all') {
                    this.toggleOpacity(true);
                    this.highlightHovered('continent', false, 'South America');
                } else if (this.hovered == 'Africa' && this.selected == 'all') {
                    this.toggleOpacity(true);
                    this.highlightHovered('continent', false, 'Africa');
                } else if (this.hovered == 'Oceania' && this.selected == 'all') {
                    this.toggleOpacity(true);
                    this.highlightHovered('continent', false, 'Oceania');
                } else if (this.hovered == 'Asia' && this.selected == 'all') {
                    this.toggleOpacity(true);
                    this.highlightHovered('continent', false, 'Asia');
                } else if (this.hovered == 'all' && this.selected == 'all') {
                    this.toggleOpacity(false);
                } else if (this.hovered == 'top10') {
                    this.toggleOpacity(true);
                    this.highlightHovered('ranking', false, 'top10');
                } else if (this.hovered == 'bottom10') {
                    this.toggleOpacity(true);
                    this.highlightHovered('ranking', false, 'bottom10');
                }
            },
            sortBy: function() {
                if (this.hovered == 'Europe') {
                    this.toggleOpacity(true);
                    this.highlightHovered('continent', false, 'Europe');
                } else if (this.hovered == 'North America') {
                    this.toggleOpacity(true);
                    this.highlightHovered('continent', false, 'North America');
                } else if (this.hovered == 'South America') {
                    this.toggleOpacity(true);
                    this.highlightHovered('continent', false, 'South America');
                } else if (this.hovered == 'Africa') {
                    this.toggleOpacity(true);
                    this.highlightHovered('continent', false, 'Africa');
                } else if (this.hovered == 'Oceania') {
                    this.toggleOpacity(true);
                    this.highlightHovered('continent', false, 'Oceania');
                } else if (this.hovered == 'Asia') {
                    this.toggleOpacity(true);
                    this.highlightHovered('continent', false, 'Asia');
                } else if (this.hovered == 'all') {
                    this.toggleOpacity(false);
                } else if (this.selected == 'top10') {
                    this.toggleOpacity(true);
                    this.highlightHovered('ranking', false, 'top10');
                } else if (this.selected == 'top10') {
                    this.toggleOpacity(true);
                    this.highlightHovered('ranking', false, 'top10');
                }
            }
        }
    }
</script>

<style lang="scss">
    @import '../assets/style/main';
    .wrapper-description {
        padding: 20px 20px;
        width: 450px;
        min-width: 300px;
        text-align: start;
        border-right: 1px solid rgba($color-black, .1);

        @media screen and (max-width: 1440px) {
            padding: 20px 15px;
        }
    }

    .img-mobile {
        display: none;
        width: 100%;

        @media #{$mobile} {
            display: flex;
        }
    }

    .only-mobile {
        display: none;

        @media #{$mobile} {
            display: block;
        }
    }

    .btn-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .not-mobile {
        @media #{$mobile} {
            display: none;
        }
    }


    .wrapper-mobile-info {
        height: auto;
        width: auto;
        background: $color-black;
        display: none;
        padding: 13px 13px 13px 13px;
        color: white;
        // border: 1px solid $color-black;
        border-radius: 4px;

        p {
            margin: 0;
            margin-bottom: 2px;
        }

        @media #{$mobile} {
            display: flex;
        }


    }
            .i {
                font-style: italic;
            }

        .sources {
            p {
                font-size: $font-size-m !important;
                margin-top: 0;
                color: $color-black-05;

                &:last-of-type {
                    margin-bottom: 10px;
                }
            }
        }

        .small {
            font-size: $font-size-m;
        }

        .l {
            text-decoration: none;
            color: $color-black;
            border-bottom: 1px solid $color-black;
            opacity: .5;

            &:hover {
                color: $color-black;
                opacity: 1;
            }
        }

        .intro {
            letter-spacing: .3px;
        }
        
        .meta {
            position: block; 

            @media #{$not-mobile} {
                position: fixed; 
                bottom: 30px;
            }
        }


</style>


