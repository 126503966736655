<template>
    <div class="legend-wrapper">
        <span class="legend-title">{{ setTitle }} <span class="continent">{{ setContinent }}</span></span>
        <div class="connection">
            <span class="mr-m">{{ setFirst }}</span>
            <div class="mark first"></div>
            <div class="connector"></div>
            <div class="mark last"></div>
            <span class="ml-m">{{ setLast }}</span>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { select as d3Select } from 'd3';

    export default {
        name: 'Legend',
        props: ['first', 'last', 'country', 'continent', 'color'],
        computed: {
            setTitle() {
                if (this.country == null) {
                    return '% women in national parliaments';
                } else {
                    return this.country;
                }
            },
            setContinent() {
                if (this.continent == null) {
                    return '';
                } else {
                    return `(${this.continent})`;
                }
            },
            setFirst() {
                if (this.first == null) {
                    return '1990';
                } else {
                    return this.first + '%';
                }
            },
            setLast() {
                if (this.last == null) {
                    return '2018';
                } else {
                    return this.last + '%';
                }
            },
            setColor() {
                if (this.color == null) {
                    return 'black';
                } else {
                    return this.color;
                }
            },
        },
        watch: {
            setColor() {
                d3Select('.legend-title')
                    .attr('style', `color: ${this.color}`)

                d3Select('.mark.first')
                    .attr('style', `border-color: ${this.color}`)

                d3Select('.mark.last')
                    .attr('style', `background: ${this.color}; border-color: ${this.color}`)

                d3Select('.connector')
                    .attr('style', `background: ${this.color}`)
            }
        }
    }
</script>

<style lang="scss">
    @import '../assets/style/main.scss';

    .legend-wrapper {
        position: absolute;
        text-align: start;
        top: 40px;
        left: 50px;
        
        .mark {
            width: 6px;
            height: 6px;
            border-radius: 100px;
            border: 1px solid $color-black;

            &.last {
                background-color: $color-black;
            }
        }

        .connector {
            width: 50px;
            height: 1px;
            background: $color-black;
        }

        .connection {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .legend-title {
            font-weight: bold;
            text-align: start;
        }

        .continent {
            font-weight: normal;
        }



    }
</style>


