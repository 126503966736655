import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    data_url: './data/women_seats_t05.csv',
    data: null,
    countries: null,
    world: null,
    year: 1990,
    hovered: null,
    sortBy: 'last',
    highlighted: 'all',
    highlighted_id: 'all',
    selected: 'all',
    ranking: {
      top: ['646','68','710','516','218','32','250','558','484','56'],
      bottom: ['598', '795', '462', '144', '8', '798', '90', '764', '270', '748']
    }
  },
  mutations: {
    SET_DATA: (state, payload) => {
      state.data = payload;
    },
    SET_COUNTRIES: (state, payload) => {
      state.countries = payload;
    },
    SET_WORLD: (state, payload) => {
      state.world = payload;
    },
    SET_YEAR: (state, payload) => {
      state.year = payload;
    },
    SET_HOVERED: (state, payload) => {
      state.hovered = payload;
    },
    SET_SORTBY: (state, payload) => {
      state.sortBy = payload;
    },
    SET_SELECTED: (state, payload) => {
      state.selected = payload;
    },
    SET_HIGHLIGHTED_ID: (state, payload) => {
      state.highlighted_id = payload;
    },
    SET_HIGHLIGHTED: (state, payload) => {
      state.highlighted = payload;
    },
  },
  actions: {
    SET_DATA: (context, payload) => {
      context.commit("SET_DATA", payload);
    },
    SET_WORLD: (context, payload) => {
      context.commit("SET_WORLD", payload);
    },
    SET_COUNTRIES: (context, payload) => {
      context.commit("SET_COUNTRIES", payload);
    },
    SET_HIGHLIGHTED_ID: (context, payload) => {
      context.commit("SET_HIGHLIGHTED_ID", payload);
    },
    SET_HOVERED: (context, payload) => {
      context.commit("SET_HOVERED", payload);
    },
    SET_YEAR: (context, payload) => {
      context.commit("SET_YEAR", payload);
    },
    SET_SORTBY: (context, payload) => {
      context.commit("SET_SORTBY", payload);

    },
    SET_SELECTED: (context, payload) => {
      context.commit("SET_SELECTED", payload);
    },
    SET_HIGHLIGHTED: (context, payload) => {
      context.commit("SET_HIGHLIGHTED", payload);
    }
  }
})
