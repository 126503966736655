<template>
    <div class="share-wrapper">
        <a class="link social" :href="shareFb" target="_blank">
            <font-awesome-icon style="transform: translateY(-2px); margin-right: 5px; width: 15px;" size="lg" far class="share-icon" :icon="facebook"/>
        </a>

        <a class="link social" :href="twitterUrl" target="_blank">
            <font-awesome-icon style="transform: translateY(-2px); margin-right: 5px; width: 15px;" size="lg" far class="share-icon" :icon="twitter"/>
        </a>

    </div>
</template>

<script>

    import { content } from '../assets/content.js'

    import { 
        faTwitterSquare,
        faFacebookSquare
    } from '@fortawesome/free-brands-svg-icons'

    export default {
        name: 'Share',
        data() {
            return {
                twitterUrl: 'https://twitter.com/intent/tweet?source=https%3A%2F%2Ffabiandinklage.com%2Fprojects%2Fgender-equality&text=Gender%20inequality%20in%20parliaments:%20A%20visual%20exploration:%20https%3A%2F%2Ffabiandinklage.com%2Fprojects%2Fgender-equality%2F&via=fdnklg'
            }
        },
        computed: {
            twitter() {
                return faTwitterSquare;
            },
            facebook() {
                return faFacebookSquare;
            },
            shareFb() {
                const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${content.urls.project2}`;
                return fbUrl;
            }
        }
    }
</script>

<style lang="scss">
    @import '../assets/style/main';

    .share-wrapper {
        .share-icon {
           font-size: 32px;
           color: $color-black;
        }

        .link {
            margin-right: 5px;
        }
    }

    
</style>


